export default [
  {
    header: 'Help',
    resource: 'Help',
    action: 'read',
  },
  {
    title: 'Faq',
    route: 'faq',
    icon: 'LifeBuoyIcon',
    action: 'read',
    resource: 'Help',
  },
  {
    title: 'Videotutorial Backend',
    route: {name:'faq-question', params:{
      category: 'Raices',
      slug:'videotutorial',
      id: 25
    }},
    icon: 'YoutubeIcon',
    action: 'read',
    resource: 'VideotutorialEduca',
    
  }
]
