export default [
    {
        header: 'Content Manager',
    },
    {
        title: 'Platforms',
        icon: 'GridIcon',
        children: [
            {
                title: 'Smile And Learn',
                children: [
                    {
                        title: 'Versions',
                        route: 'sl-versions',
                    },
                    {
                        title: 'Customizations',
                        route: 'sl-customizations',
                    },
                    {
                        title: 'Plugins',
                        route: 'sl-plugins',
                        disabled: true,
                    },
                ],
            },
            {
                title: 'Breaking Barriers',
                children: [
                    {
                        title: 'Plugins',
                        route: 'bb-plugins',
                    },
                ],
            },
        ],
    },
    {
        title: 'Inteligencias',
        route: 'inteligences',
        icon: 'ZapIcon',
    },
    {
        title: 'Categorías',
        route: 'categories',
        icon: 'MenuIcon',
    },
    {
        title: 'Subcategorías',
        route: 'subcategories',
        icon: 'ListIcon',
    },
    {
        title: 'Contenidos',
        route: 'contents',
        icon: 'BoxIcon',
    },
    {
        title: 'Hierarchy',
        route: 'hierarchy',
        icon: 'GitMergeIcon',
    }
]