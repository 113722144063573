export default [
    {
        header: 'Smile and Learn',
        resource: 'Dreamers',
        action: 'read',
    },
    {
        title: 'Groups',
        route: 'groups',
        icon: 'UsersIcon',
        resource: 'Groups',
        action: 'read',
    },
    {
        title: 'Dreamers',
        route: 'dreamers',
        icon: 'SmileIcon',
        resource: 'Dreamers',
        action: 'read',
    },
    {
        title: 'Learning Paths',
        route: 'learning-paths',
        icon: 'CompassIcon',
        resource: 'Learning Paths',
        action: 'read',
    },
    {
        title: 'Educational Resources',
        route: 'resources',
        icon: 'FileTextIcon',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Informes',
        icon: 'FolderIcon',
        tag: 'new',
        tagVariant: 'light-success',
        children: [
            {
                title: 'Listado',
                route: 'reports',
                resource: 'Reports',
                action: 'read',
            },
            {
                title: 'Crear',
                route: 'reports-create',
                resource: 'Reports',
                action: 'create',
            },
        ],
            
    },
    {
        header: 'School Structure',
        resource: 'School Structure',
        action: 'read',
    },
    {
        title: 'Handle',
        route: 'school-structure',
        icon: 'GridIcon',
        resource: 'School Structure',
        action: 'read',
    },
]
