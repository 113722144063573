export default [
    {
        header: 'Admin',
        resource: 'Schools',
        action: 'read',
    },
    /* {
        title: 'Promociones',
        route: 'promociones',
        icon: 'AwardIcon',
    }, */
    {
        title: 'Partners',
        route: 'partners',
        icon: 'BriefcaseIcon',
        resource: 'Partners',
        action: 'read',
    },
    {
        title: 'Colegios',
        route: 'schools',
        icon: 'BookIcon',
        resource: 'Schools',
        action: 'create',
    },
    {
        title: 'Licencias',
        route: 'licences',
        icon: 'UserIcon',
        resource: 'Licences',
        action: 'create',
    },
    {
        title: 'Roles & Permisos',
        route: 'roles',
        icon: 'CoffeeIcon',
        resource: 'Roles',
        action: 'read',
    }
]