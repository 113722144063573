export default [
  {
    header: 'Integrations',
    action: 'read',
    resource: 'Raíces',
  },
  {
    title: 'Raíces',
    route: 'raices',
    icon: 'CodesandboxIcon',
    action: 'read',
    resource: 'Raíces',
  }
]